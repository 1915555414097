/**************** common css start ****************/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,300,400i,400,700|Poppins:400,500,600,700");
/* line 3, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
body {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

/* line 9, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
section {
  overflow: hidden !important;
}

/* line 12, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.message_submit_form:focus {
  outline: none;
}

/* line 15, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
input:hover, input:focus {
  outline: none !important;
}

/* line 18, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.gray_bg {
  background-color: #F7F7F7;
}

/* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.section-padding {
  padding: 180px 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section-padding {
    padding: 80px 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section-padding {
    padding: 70px 0px;
  }
}

@media (max-width: 576px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section-padding {
    padding: 70px 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section-padding {
    padding: 70px 0px;
  }
}

/* line 36, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.padding_top {
  padding-top: 180px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 36, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_top {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 36, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

@media (max-width: 576px) {
  /* line 36, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 36, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_top {
    padding-top: 70px;
  }
}

/* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.padding_bottom {
  padding-bottom: 180px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_bottom {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_bottom {
    padding-bottom: 70px;
  }
}

@media (max-width: 576px) {
  /* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_bottom {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .padding_bottom {
    padding-bottom: 70px;
  }
}

/* line 66, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
a {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 69, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
a:hover, a :hover {
  color: #8e44ad;
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 76, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h1, h2, h3, h4, h5, h6 {
  color: #181919;
  font-family: "Poppins", sans-serif;
}

/* line 80, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
a {
  color: #181919;
  font-family: "Poppins", sans-serif;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 84, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
a:hover {
  color: #8e44ad;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 89, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
p {
  color: #2c2c2c;
  font-family: "Poppins", sans-serif;
  line-height: 1.625;
  font-size: 14px;
  margin-bottom: 0px;
}

/* line 96, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h1 {
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600px !important;
}

@media (max-width: 576px) {
  /* line 96, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  h1 {
    font-size: 24px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 96, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  h1 {
    font-size: 28px;
    line-height: 26px;
  }
}

/* line 110, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h2 {
  font-size: 29px;
  line-height: 28px;
}

@media (max-width: 576px) {
  /* line 110, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  h2 {
    font-size: 22px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 110, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  h2 {
    font-size: 24px;
    line-height: 25px;
  }
}

/* line 124, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h3 {
  font-size: 24px;
  line-height: 25px;
}

@media (max-width: 576px) {
  /* line 124, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  h3 {
    font-size: 20px;
  }
}

/* line 133, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h4 {
  font-size: 20px;
  line-height: 23px;
}

/* line 138, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
h5 {
  font-size: 18px;
  line-height: 22px;
}

/* line 143, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
img {
  max-width: 100%;
}

/* line 146, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
a:focus, .button:focus, button:focus, .btn:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
  -webkit-transition: 1s;
  transition: 1s;
}

/* line 154, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
::placeholder.hide-on-focus:focus::-webkit-input-placeholder {
  color: white;
}

/* line 158, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
::placeholder.hide-on-focus:focus:-moz-placeholder {
  /* Firefox 18- */
  color: white;
}

/* line 162, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
::placeholder.hide-on-focus:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
}

/* line 166, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
::placeholder.hide-on-focus:focus:-ms-input-placeholder {
  color: white;
}

/* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.section_tittle h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 80px;
  position: relative;
}

/* line 179, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.section_tittle h1:after {
  position: absolute;
  left: 0;
  height: 2px;
  width: 90px;
  content: "";
  background-color: #8e44ad;
  bottom: -27px;
}

@media (max-width: 576px) {
  /* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section_tittle h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section_tittle h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .section_tittle h1 {
    font-size: 30px;
    line-height: 40px;
  }
}

/* line 205, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* line 210, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.area-padding {
  padding: 130px 0px;
}

/* line 213, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.container {
  max-width: 1170px;
}

/* line 216, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.mb_110 {
  margin-bottom: 110px;
}

@media (max-width: 576px) {
  /* line 216, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .mb_110 {
    margin-bottom: 220px;
  }
}

/* line 223, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.pt_30 {
  padding-top: 30px;
}

@media (max-width: 991px) {
  /* line 223, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
  .pt_30 {
    padding-top: 0;
  }
}

/* line 232, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_common.scss */
.z_index {
  z-index: 9 !important;
  position: relative;
}

/**************** common css end ****************/
/**************** extend css start ****************/
/* line 29, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_extends.scss */
.banner_part {

}

/**************** extend css start ****************/
/**************** menu part css start ****************/
/* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_menu.scss */
.main_menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

@media (max-width: 991px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_menu.scss */
  .main_menu {
    padding: 15px 0;
  }
}

/* line 14, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_menu.scss */
.main_menu:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  opacity: .9;
}


/**************** menu part css end ****************/
/**************** banner part css start ****************/
/* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part {
  height: 900px;
  display: flex;
  align-items: center;
  position: relative;
  background-image: linear-gradient(to bottom left,  rgba(204,214,39, 0.50), rgba(142,68,173, 0.75)), url(../img/banner_overlay.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  background: -webkit-linear-gradient(5deg, #8e44ad 0%, rgba(18, 1, 248, 0.2) 100%);
  background: -webkit-linear-gradient(5deg, #8e44ad 0%, rgba(18, 1, 248, 0.2) 100%);
  background: -ms-linear-gradient(5deg, #8e44ad 0%, rgba(18, 1, 248, 0.2) 100%);
}

/* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media (max-width: 576px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part {
    height: 500px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part {
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part {
    height: 500px;
  }
}

/* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text {
  display: table;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 99;
  margin-top: 60px;
}

@media (max-width: 991px) {
  /* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text {
    text-align: center;
    margin-top: 10px;
  }
}

/* line 41, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text .banner_text_iner {
  display: table-cell;
  vertical-align: middle;
}

/* line 45, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text h5 {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 35px;
  color: #fff;
  margin-bottom: 0;
  line-height: 25px;
}

@media (max-width: 991px) {
  /* line 45, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text h5 {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 45, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text h5 {
    margin-bottom: 10px;
  }
}

/* line 60, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text h2 {
  font-size: 72px;
  line-height: 75px;
  margin: 0px 0px 20px;
  font-weight: 700;
  letter-spacing: 3px;
  color: #fff;
}

@media (max-width: 991px) {
  /* line 60, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text h2 {
    font-size: 30px;
    margin-bottom: 15px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 60, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text h2 {
    font-size: 40px;
    margin-bottom: 0;
    line-height: 1.4;
  }
}

/* line 79, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text p {
  color: #f4f4f4;
  line-height: 2.25;
  font-size: 16px;
  margin-top: 46px;
}

@media (max-width: 991px) {
  /* line 79, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text p {
    margin-top: 0;
    line-height: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 79, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text p {
    margin-top: 15px;
  }
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_text .banner_btn {
  display: inline-block;
  margin-top: 40px;
  padding: 10px 30px;
}

@media (max-width: 991px) {
  /* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .banner_text .banner_btn {
    margin-top: 20px;
  }
}

/* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .video_popup {
  position: absolute;
  left: 0;
  right: 0;
  width: 95px;
  height: 95px;
  text-align: center;
  padding-top: 102px;
  padding-left: 34px;
  z-index: 99 !important;
  margin: 0 auto;
}

/* line 114, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .video_popup span {
  width: 95px;
  height: 95px;
  background-color: #8e44ad;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 82px;
  padding-left: 5px;
  font-size: 20px;
  color: #fff;
  border: 7px solid #fff;
  box-shadow: 0px 5px 25.5px 4.5px rgba(20, 48, 58, 0.2);
}

/* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .video_popup span:hover {
  background-color: #d8b25a;
}

@media (max-width: 576px) {
  /* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .video_popup {
    top: 100px;
    padding-top: 70px;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .video_popup {
    top: 100px;
    padding-top: 70px;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
  .banner_part .video_popup {
    top: 100px;
    padding-top: 80px;
    padding-left: 0px;
  }
}

/* line 149, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon {
  position: absolute;
  transform: rotate(-90deg);
  z-index: 9999 !important;
  left: -310px;
  top: 49%;
}

/* line 155, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon li {
  color: #777777;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
}

/* line 159, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon li a {
  color: #777777;
}

/* line 162, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon li span {
  margin-right: 10px;
}

/* line 167, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon i.fas.fa-circle {
  font-size: 7px;
  margin: 0px 10px 0px 20px;
  color: #8e44ad;
}

/* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .banner_social_icon a:hover {
  color: #8e44ad !important;
}

/* line 176, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_banner.scss */
.banner_part .list-inline-item:not(:last-child) {
  margin-right: 0px;
}

/**************** hero part css end ****************/
/************about css start***************/
/* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part {
  position: relative;
  z-index: 99;
}

/* line 7, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .section_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
  padding-left: 195px;
  text-transform: capitalize;
  line-height: 24px;
}

@media (max-width: 991px) {
  /* line 7, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .section_tittle h2 {
    padding-left: 175px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 7, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .section_tittle h2 {
    padding-left: 175px;
    margin-bottom: 20px;
  }
}

/* line 26, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .section_tittle h2:after {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 180px;
  height: 1px;
  background-color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 26, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .section_tittle h2:after {
    width: 140px;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 26, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .section_tittle h2:after {
    width: 140px;
    left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 7, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .section_tittle h2 {
    margin-bottom: 40px;
  }
}

/* line 48, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .section_tittle h2 span {
  color: #8e44ad;
}

/* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_img {
  position: relative;
  z-index: 1;
}

/* line 58, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_img:after {
  position: absolute;
  content: "";
  width: 48%;
  height: 100%;
  top: 70px;
  right: -20px;
  background-image: url(../img/patten_bg.png);
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 58, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_img:after {
    max-width: 991px;
    top: 36px;
    right: -15px;
  }
}

.imagenabout {
  max-width: 500px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 58, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_img:after {
    top: 36px;
    right: -15px;
  }
}

@media (max-width: 991px) {
  /* line 78, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text {
    margin-top: 25px;
  }
}

/* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 37px;
  position: relative;
  padding-right: 20px;
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text h2 span {
  color: #8e44ad;
}

@media (max-width: 576px) {
  /* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 25px;
    margin-bottom: 35px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 30px;
    margin-bottom: 35px;
    line-height: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text h2 {
    font-size: 30px;
    margin-bottom: 35px;
    line-height: 40px;
  }
}

/* line 122, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text p {
  line-height: 1.929;
  margin-bottom: 50px;
  font-weight: 300;
}

@media (max-width: 991px) {
  /* line 122, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text p {
    margin-bottom: 0;
  }
}

/* line 134, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter {
  margin-top: 74px;
}

@media (max-width: 991px) {
  /* line 134, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .about_text .about_part_counter {
    margin-top: 35px;
  }
}

/* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter .single_counter {
  width: 33.33%;
  text-align: center;
  float: left;
}

/* line 147, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter .single_counter span {
  font-size: 50px;
  font-weight: 300;
  line-height: 45px;
}

/* line 153, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter .single_counter p {
  font-size: 16px;
  color: #000;
  text-transform: uppercase;
}

/* line 159, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter .single_counter:first-child {
  text-align: left;
}

/* line 163, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
.about_part .about_text .about_part_counter .single_counter:last-child {
  text-align: right;
}

@media (max-width: 991px) {
  /* line 170, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_about.scss */
  .about_part .btn_1 {
    margin-top: 25px;
  }
}

/**************** service_part css start ****************/
/* line 4, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .section_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  padding-left: 206px;
  text-transform: capitalize;
  line-height: 24px;
}

@media (max-width: 991px) {
  /* line 4, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .section_tittle h2 {
    padding-left: 130px;
    margin-bottom: 0;
  }
}

/* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .section_tittle h2:after {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 180px;
  height: 1px;
  background-color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .section_tittle h2:after {
    width: 110px;
  }
}

/* line 39, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .section_tittle h2 span {
  color: #8e44ad;
}

/* line 44, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .mt_60 {
  margin-top: 60px;
}

@media (max-width: 991px) {
  /* line 47, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_text {
    margin-top: 25px;
  }
}

/* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_text h2 {
  font-size: 36px;
  margin-bottom: 34px;
  font-weight: 700;
  line-height: 51px;
}

/* line 59, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_text h2 span {
  color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_text h2 {
    font-size: 25px;
    margin-bottom: 15px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_text h2 {
    font-size: 40px;
    margin-bottom: 15px;
  }
}

/* line 71, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_text h2 span {
  font-weight: 400;
}

/* line 76, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_text p {
  margin-bottom: 15px;
  line-height: 1.8;
}

/* line 80, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_text .btn_1 {
  margin-top: 45px;
}

@media (max-width: 991px) {
  /* line 80, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_text .btn_1 {
    margin-top: 10px;
  }
}

/* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .single_service_text {
  padding: 25px;
  margin-top: 30px;
  background-color: #f4f4f4;
  position: relative;
}

@media (max-width: 991px) {
  /* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .single_service_text {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .single_service_text {
    margin-top: 15px;
  }
}

/* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .single_service_text img {
  position: absolute;
  display: block;
}

/* line 107, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .single_service_text h4 {
  font-size: 18px;
  font-weight: 700;
  padding-left: 80px;
  text-transform: capitalize;
}

/* line 119, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .single_service_text p {
  padding-top: 20px;
  line-height: 2;
}

@media (max-width: 991px) {
  /* line 119, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .single_service_text p {
    padding-top: 30px;
  }
}

/* line 132, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_part_iner .col-lg-6:first-child {
  margin-top: 60px;
}

@media (max-width: 991px) {
  /* line 132, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_part_iner .col-lg-6:first-child {
    margin-top: 0;
  }
}

/* line 141, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.service_part .service_part_iner .col-lg-6:last-child {
  margin-top: -60px;
}

@media (max-width: 991px) {
  /* line 141, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .service_part .service_part_iner .col-lg-6:last-child {
    margin-top: 0;
  }
}

@media (max-width: 991px) {
  /* line 154, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .mt_30 {
    margin-top: 30px;
  }
}

/* line 161, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.single_page_service {
  padding: 180px 0 116px;
}

@media (max-width: 991px) {
  /* line 161, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .single_page_service {
    padding: 70px 0 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 161, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
  .single_page_service {
    padding: 75px 0 50px;
  }
}

/* line 169, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.single_page_service .btn_1 {
  background-color: #8e44ad;
  color: #fff;
}

/* line 172, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_service.scss */
.single_page_service .btn_1:hover {
  color: #000;
}

/*************** portfolio project css ******************/
/* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .section_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 80px;
  line-height: 24px;
}

/* line 14, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .section_tittle h2:after {
  position: absolute;
  top: 12px;
  left: 191px;
  content: "";
  width: 180px;
  height: 1px;
  background-color: #000;
}

@media (max-width: 991px) {
  /* line 14, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .section_tittle h2:after {
    width: 100px;
    left: 157px;
  }
}

/* line 27, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .section_tittle h2 span {
  color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .section_tittle h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .section_tittle h2 {
    margin-bottom: 40px;
  }
}

/* line 39, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio-filter {
  position: absolute;
}

@media (max-width: 991px) {
  /* line 39, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .portfolio-filter {
    position: relative;
  }
}

/* line 47, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio-filter h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 40px;
}

@media (max-width: 991px) {
  /* line 47, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .portfolio-filter h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

/* line 59, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio-filter h2 span {
  color: #8e44ad;
}

/* line 63, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio-filter a {
  font-size: 14px;
  border: 1px solid #eeeeee;
  text-transform: uppercase;
  padding: 8px 15px;
  display: inline-block;
  margin-right: 22px;
  margin-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 991px) {
  /* line 63, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .portfolio-filter a {
    margin-right: 15px;
  }
}

/* line 79, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio-filter .active {
  border: 1px solid #8e44ad;
  background-color: #8e44ad;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 87, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .nav-tabs {
  border-bottom: 0px solid transparent;
}

/* line 90, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .tab-content > .active {
  display: flex;
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio_box {
  position: relative;
}

@media (max-width: 991px) {
  /* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .portfolio_box {
    margin-bottom: 15px;
  }
}

/* line 101, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio_box .short_info {
  position: absolute;
  background-color: #fff;
  bottom: 0;
  padding: 17px 40px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 101, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .portfolio_box .short_info {
    padding: 17px 17px;
  }
}

/* line 115, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio_box .short_info p {
  color: #8e44ad;
  text-transform: capitalize;
  letter-spacing: 1.5px;
}

/* line 120, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .portfolio_box .short_info h4 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 9px;
  line-height: 30px;
}

/* line 129, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
.portfolio_area .single_portfolio_project:first-child {
  margin-bottom: 130px;
}

@media (max-width: 991px) {
  /* line 129, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project.scss */
  .portfolio_area .single_portfolio_project:first-child {
    margin-bottom: 0;
  }
}

/************** project  details gallery ******************/
@media (max-width: 991px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery {
    padding: 70px 0 50px;
  }
}

/* line 7, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_tittle {
  max-width: 1140px;
  margin: 0 auto;
}

/* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 80px;
  line-height: 24px;
}

/* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_tittle h2:after {
  position: absolute;
  top: 12px;
  left: 269px;
  content: "";
  width: 180px;
  height: 1px;
  background-color: #000;
}

@media (max-width: 576px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2:after {
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2:after {
    width: 100px;
    left: 260px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2:after {
    width: 150px;
    left: 260px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2:after {
    width: 150px;
    left: 280px;
  }
}

/* line 51, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_tittle h2 span {
  color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_tittle h2 {
    margin-bottom: 40px;
  }
}

/* line 65, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .col-lg-12 {
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  /* line 65, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .col-lg-12 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 65, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .col-lg-12 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/* line 81, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .grid-sizer,
.project_gallery .grid-item {
  width: 25%;
  height: auto;
}

@media (max-width: 576px) {
  /* line 81, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .grid-sizer,
  .project_gallery .grid-item {
    width: 100%;
    margin: 0;
  }
  /* line 90, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .grid-sizer img,
  .project_gallery .grid-item img {
    width: 100%;
  }
}

/* line 96, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_img {
  position: relative;
  z-index: 1;
}

/* line 100, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_img:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  -webkit-transition: 1s;
  transition: 1s;
}

@media (max-width: 576px) {
  /* line 96, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_img {
    margin-bottom: 20px;
  }
}

/* line 116, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_img img {
  max-width: 100%;
}

/* line 121, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .big_weight {
  width: 50%;
}

@media (max-width: 576px) {
  /* line 121, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .big_weight {
    width: 100%;
    margin: 0;
  }
  /* line 128, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .big_weight img {
    width: 100%;
  }
}

/* line 134, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .big_height {
  width: 50%;
  height: auto;
}

@media (max-width: 576px) {
  /* line 134, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .big_height {
    width: 100%;
    margin: 0;
  }
  /* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .big_height img {
    width: 100%;
  }
}

/* line 148, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery h2 {
  text-transform: capitalize;
  font-weight: 800;
  font-size: 48px;
  margin-bottom: 80px;
}

@media (max-width: 576px) {
  /* line 148, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery h2 {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 148, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery h2 {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 148, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery h2 {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 148, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery h2 {
    margin-bottom: 40px;
    font-size: 30px;
  }
}

/* line 175, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text {
  position: absolute;
  padding: 0 0 55px 39px;
  bottom: -60px;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 184, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text p {
  font-size: 14px;
  font-weight: 400;
  color: #8e44ad;
  text-transform: uppercase;
}

/* line 191, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text h3 {
  font-weight: 600;
  font-size: 28px;
  color: #fff;
  margin: 12px 0 8px;
}

/* line 196, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text h3 a {
  color: #fff;
}

/* line 198, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text h3 a:hover {
  color: #8e44ad;
}

/* line 204, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text ul {
  border-top: 1px solid #fff;
  padding-top: 10px;
}

/* line 208, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text ul li {
  display: inline-block;
  color: #fff;
  margin-right: 25px;
}

@media (max-width: 991px) {
  /* line 208, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_hover_text ul li {
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 208, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .project_gallery .project_gallery_hover_text ul li {
    margin-right: 15px;
  }
}

/* line 219, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .project_gallery_hover_text ul li span {
  color: #fff;
  margin-right: 12px;
  font-size: 16px;
}

/* line 231, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .grid-item:hover .project_img:after {
  opacity: .5;
}

/* line 236, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.project_gallery .grid-item:hover .project_gallery_hover_text {
  visibility: visible;
  opacity: 1;
  bottom: 0;
}

/* line 245, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details {
  padding: 0 0 150px;
}

@media (max-width: 991px) {
  /* line 245, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details {
    padding: 0 0 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 245, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details {
    padding: 0 0 50px;
  }
}

/* line 257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img p {
  font-size: 16px;
  color: #434343;
  line-height: 1.875;
  margin: 30px 0;
}

@media (max-width: 991px) {
  /* line 257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img p {
    margin: 10px 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img p {
    margin: 10px 0;
  }
}

/* line 272, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text {
  padding: 44px 0 0;
  z-index: 99;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 277, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text p {
  font-size: 14px;
  font-weight: 400;
  color: #8e44ad;
  text-transform: uppercase;
  margin: 0;
}

/* line 285, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text h3 {
  font-weight: 600;
  font-size: 28px;
  color: #000;
  margin: 12px 0 8px;
}

@media (max-width: 991px) {
  /* line 285, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img .project_gallery_hover_text h3 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 285, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img .project_gallery_hover_text h3 {
    font-size: 20px;
  }
}

/* line 300, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text ul {
  padding-top: 10px;
}

/* line 303, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text ul li {
  display: inline-block;
  color: #b1b1b1;
  margin-right: 25px;
}

@media (max-width: 991px) {
  /* line 303, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img .project_gallery_hover_text ul li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 303, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
  .gallery_details .project_img .project_gallery_hover_text ul li {
    margin-right: 10px;
  }
}

/* line 314, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_project_gallery.scss */
.gallery_details .project_img .project_gallery_hover_text ul li span {
  color: #b1b1b1;
  margin-right: 12px;
  font-size: 16px;
}

/************** blog part css****************/
/* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .blog_part_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 80px;
  line-height: 24px;
}

/* line 15, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .blog_part_tittle h2:after {
  position: absolute;
  top: 12px;
  left: 131px;
  content: "";
  width: 180px;
  height: 1px;
  background-color: #000;
}

@media (max-width: 991px) {
  /* line 15, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .blog_part_tittle h2:after {
    width: 100px;
    left: 127px;
  }
}

/* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .blog_part_tittle h2 span {
  color: #8e44ad;
}

@media (max-width: 991px) {
  /* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .blog_part_tittle h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .blog_part_tittle h2 {
    margin-bottom: 40px;
  }
}

/* line 44, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog {
  border: 1px solid #b7b7b7;
  padding: 25px;
}

@media (max-width: 991px) {
  /* line 44, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog {
    padding: 15px;
  }
}

/* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content {
  padding: 38px 42px 36px;
  background-color: #f4f4f4;
}

@media (max-width: 576px) {
  /* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content {
    padding: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content {
    padding: 20px;
  }
}

/* line 70, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content p {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  /* line 70, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content p {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 70, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content p {
    margin-bottom: 15px;
  }
}

/* line 87, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content p a {
  color: #8e44ad;
}

/* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.481;
  margin-bottom: 25px;
}

@media (max-width: 576px) {
  /* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content h4 {
    font-size: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content h4 {
    font-size: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 92, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .single_blog .single_appartment_content h4 {
    font-size: 21px;
    margin-bottom: 15px;
  }
}

/* line 117, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content .list-unstyled li {
  display: inline;
  margin-right: 17px;
  color: #2c2c2c;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 124, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content .list-unstyled li:hover {
  color: #8e44ad;
}

/* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content .list-unstyled li:hover a {
  color: #8e44ad;
}

/* line 132, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .single_blog .single_appartment_content .list-unstyled li a {
  margin-right: 8px;
  color: #2c2c2c;
}

/* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog {
  margin-bottom: 23px;
  display: inline-block;
  padding: 21px 25px;
  background-color: #fff;
  border: 1px solid #b7b7b7;
}

/* line 149, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog:last-child {
  margin-bottom: 0px;
}

@media (max-width: 576px) {
  /* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog {
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog {
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 15px;
    width: 48%;
    float: left;
  }
  /* line 166, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog:last-child {
    margin-left: 4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog {
    margin-top: 20px;
    margin-bottom: 0px;
    padding: 15px;
    width: 48%;
    float: left;
  }
  /* line 178, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog:last-child {
    margin-left: 4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 142, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog {
    margin-bottom: 20px;
  }
}

/* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body {
  background-color: #f4f4f4;
  padding: 35px 26px;
}

@media (max-width: 991px) {
  /* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog .media-body {
    padding: 25px 16px;
  }
}

/* line 195, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body p {
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  /* line 195, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog .media-body p {
    margin-bottom: 5px;
  }
}

/* line 202, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body p a {
  color: #8e44ad;
  text-transform: uppercase;
  font-size: 12px;
}

/* line 209, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body h5 {
  font-weight: 600;
  line-height: 1.667;
  margin: 15px 0px;
  font-size: 24px;
}

@media (max-width: 576px) {
  /* line 209, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog .media-body h5 {
    line-height: 1.3;
    font-size: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 209, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog .media-body h5 {
    line-height: 1.3;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 209, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part .right_single_blog .single_blog .media-body h5 {
    line-height: 1.3;
    font-size: 20px;
  }
}

/* line 232, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body .list-unstyled li {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  cursor: pointer;
  color: #2c2c2c;
}

/* line 239, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body .list-unstyled li:hover {
  color: #8e44ad;
}

/* line 242, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body .list-unstyled li:hover a {
  color: #8e44ad;
}

/* line 248, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.blog_part .right_single_blog .single_blog .media-body .list-unstyled li a {
  margin-right: 6px;
  color: #2c2c2c;
}

/* line 261, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .single_blog {
  padding-bottom: 50px;
}

/* line 264, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .single_blog:last-child {
  padding-bottom: 0px;
}

/* line 269, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .appartment_img {
  position: relative;
}

/* line 272, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .appartment_img .date {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  box-shadow: 0px 4px 15px 0px rgba(9, 30, 107, 0.1);
  border-radius: 5px;
  background-color: #181919;
  position: absolute;
  left: 40px;
  bottom: -20px;
}

/* line 284, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .appartment_img .date h3 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 5px;
  padding-top: 18px;
}

/* line 291, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
.single_blog_post .appartment_img .date h5 {
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 302, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 15% !important;
  }
  /* line 306, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part img {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 314, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 24% !important;
  }
  /* line 319, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_part img {
    width: 100%;
  }
}

@media (max-width: 576px) {
  /* line 326, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog_part.scss */
  .blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 24% !important;
  }
}

/**********************footer part css*******************/
/* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part {
  background-color: #8e44ad;
  padding: 130px 0px 25px;
}

@media (max-width: 576px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part {
    padding: 70px 0px 25px;
  }
}

/* line 17, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .footer_logo {
  border-bottom: 1px solid #353537;
  margin-bottom: 35px;
  padding-bottom: 22px;
}

@media (max-width: 576px) {
  /* line 22, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 22, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 22, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part {
    margin-bottom: 20px;
  }
}

/* line 35, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part h4 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
  font-weight: 600;
}

@media (max-width: 576px) {
  /* line 35, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 35, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 35, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part h4 {
    margin-bottom: 15px;
  }
}

/* line 53, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part p {
  color: #CCD627;
  line-height: 2;
  margin-bottom: 10px;
}

/* line 59, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part a img {
  margin-top: 20px;
}

/* line 65, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .list-unstyled li a {
  color: #CCD627;
  margin-bottom: 10px;
  display: inline-block;
}

/* line 69, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .list-unstyled li a:hover {
  color: #fff !important;
}

/* line 75, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .social_icon {
  float: left;
  margin-top: 38px;
}

@media (max-width: 991px) {
  /* line 75, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .single_footer_part .social_icon {
    margin-top: 15px;
  }
}

/* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .social_icon li a {
  margin-left: 0;
  margin-right: 25px;
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .mail_part {
  position: relative;
}

/* line 95, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .mail_part input {
  padding: 8px 22px;
  font-size: 13px;
  border-radius: 50px;
  border: 2px solid #fff;
  margin-top: 20px;
  width: 100%;
}

/* line 103, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .single_footer_part .mail_part .email_icon {
  position: absolute;
  right: 0px;
  top: 20px;
  width: 60px;
  height: 39px;
  background-color: #CCD627;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  line-height: 10px;
  border: 2px solid #8e44ad;
}

/* line 118, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part hr {
  background-color: #494d58;
  margin-top: 50px;
  margin-bottom: 25px;
}

/* line 123, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .footer_icon {
  text-align: right;
}

/* line 125, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .footer_icon li {
  display: inline-block;
}

/* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .footer_icon li a {
  font-size: 14px;
  color: #fff;
  margin-left: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 576px) {
  /* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .footer_icon li a {
    margin-left: 0px;
    margin-right: 25px;
  }
}

/* line 146, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .footer_icon li a :hover {
  color: #8e44ad;
}

@media (max-width: 576px) {
  /* line 123, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
  .footer_part .footer_icon {
    float: left;
    margin-top: 15px;
  }
}

/* line 166, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .copyright_text p {
  color: #888;
}

/* line 169, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .copyright_text span {
  color: #8e44ad;
  font-size: 12px;
}

/* line 174, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .copyright_text p a {
  color: #8e44ad;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 177, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .copyright_text p a:hover {
  color: #8e44ad;
}

/* line 181, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_footer.scss */
.footer_part .info {
  color: red !important;
}

/* line 75, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.sample-text-area {
  background: #fff;
  padding: 100px 0 70px 0;
}

/* line 80, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}

/* line 85, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
b,
sup,
sub,
u,
del {
  color: #8e44ad;
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h1 {
  font-size: 36px;
}

/* line 97, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h2 {
  font-size: 30px;
}

/* line 101, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h3 {
  font-size: 24px;
}

/* line 105, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h4 {
  font-size: 18px;
}

/* line 109, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h5 {
  font-size: 16px;
}

/* line 113, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h6 {
  font-size: 14px;
}

/* line 117, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.2em;
}

/* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #828bb2;
}

/* line 137, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.button-area {
  background: #fff;
}

/* line 138, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}

/* line 146, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}

/* line 149, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}

/* line 155, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* line 166, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn:focus {
  outline: none;
}

/* line 169, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}

/* line 173, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.large {
  line-height: 45px;
}

/* line 176, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.medium {
  line-height: 30px;
}

/* line 179, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.small {
  line-height: 25px;
}

/* line 182, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.radius {
  border-radius: 3px;
}

/* line 185, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.circle {
  border-radius: 20px;
}

/* line 188, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* line 195, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.arrow span {
  margin-left: 10px;
}

/* line 199, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.default {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 203, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 208, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 211, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.default-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 217, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.primary {
  color: #fff;
  background: #8e44ad;
  border: 1px solid transparent;
}

/* line 221, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.primary:hover {
  color: #8e44ad;
  border: 1px solid #8e44ad;
  background: #fff;
}

/* line 227, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.primary-border {
  color: #8e44ad;
  border: 1px solid #8e44ad;
  background: #fff;
}

/* line 231, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.primary-border:hover {
  color: #fff;
  background: #8e44ad;
  border: 1px solid transparent;
}

/* line 237, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 241, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 247, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}

/* line 251, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}

/* line 257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 261, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 267, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}

/* line 271, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}

/* line 277, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 281, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 287, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}

/* line 291, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}

/* line 297, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 301, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 307, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}

/* line 311, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}

/* line 317, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.link {
  color: #415094;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}

/* line 322, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.link:hover {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
}

/* line 328, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.link-border {
  color: #415094;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}

/* line 333, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.link-border:hover {
  color: #415094;
  background: #f9f9ff;
  border: 1px solid transparent;
}

/* line 339, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}

/* line 347, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #f9f9ff;
  border-left: 2px solid #8e44ad;
}

/* line 353, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table-wrap {
  overflow-x: scroll;
}

/* line 357, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table {
  background: #f9f9ff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}

/* line 361, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}

/* line 365, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .country {
  width: 28.07%;
}

/* line 368, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .visit {
  width: 19.74%;
}

/* line 371, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}

/* line 375, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-head {
  display: flex;
}

/* line 377, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #415094;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}

/* line 387, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}

/* line 391, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}

/* line 399, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .country img {
  margin-right: 15px;
}

/* line 404, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}

/* line 408, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}

/* line 411, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}

/* line 414, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}

/* line 417, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}

/* line 420, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}

/* line 423, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}

/* line 426, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}

/* line 429, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}

/* line 432, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}

/* line 441, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
}

/* line 449, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.list-style {
  width: 14px;
  height: 14px;
}

/* line 455, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}

/* line 459, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #8e44ad;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}

/* line 473, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list {
  margin-left: 30px;
}

/* line 475, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #8e44ad;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 480, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 488, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #8e44ad;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 494, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list-alpha li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 502, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #8e44ad;
  font-weight: 500;
  line-height: 1.82em !important;
}

/* line 508, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.ordered-list-roman li span {
  font-weight: 300;
  color: #828bb2;
}

/* line 515, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 523, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input:focus {
  outline: none;
}

/* line 528, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.input-group-icon {
  position: relative;
}

/* line 530, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}

/* line 535, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.input-group-icon .icon i {
  color: #797979;
}

/* line 540, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.input-group-icon .single-input {
  padding-left: 45px;
}

/* line 545, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}

/* line 555, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-textarea:focus {
  outline: none;
}

/* line 560, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 568, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-primary:focus {
  outline: none;
  border: 1px solid #8e44ad;
}

/* line 574, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 582, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}

/* line 588, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
}

/* line 596, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}

/* line 602, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 609, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* line 619, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #8e44ad;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 632, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-switch input:checked + label {
  left: 19px;
}

/* line 639, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 646, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 655, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 663, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* line 677, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 693, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input:checked + label:after {
  left: 19px;
}

/* line 696, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-switch input:checked + label:before {
  background: #8e44ad;
}

/* line 704, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 711, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 720, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* line 728, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

/* line 742, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* line 758, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input:checked + label:after {
  left: 19px;
}

/* line 761, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}

/* line 769, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 776, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 785, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 798, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-checkbox input:checked + label {
  background: url(../img/elements/primary-check.png) no-repeat center center/cover;
  border: none;
}

/* line 806, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 813, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 822, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 835, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-checkbox input:checked + label {
  background: url(../img/elements/success-check.png) no-repeat center center/cover;
  border: none;
}

/* line 843, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 850, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 859, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 871, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 876, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-checkbox input:checked + label {
  background: url(../img/elements/disabled-check.png) no-repeat center center/cover;
  border: none;
}

/* line 884, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 891, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 900, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 913, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.primary-radio input:checked + label {
  background: url(../img/elements/primary-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 921, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 928, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 937, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 950, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.confirm-radio input:checked + label {
  background: url(../img/elements/success-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 958, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #f9f9ff;
  position: relative;
  cursor: pointer;
}

/* line 965, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* line 974, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}

/* line 986, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}

/* line 991, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.disabled-radio input:checked + label {
  background: url(../img/elements/disabled-radio.png) no-repeat center center/cover;
  border: none;
}

/* line 999, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select {
  height: 40px;
}

/* line 1001, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 20px;
  padding-right: 40px;
}

/* line 1008, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1015, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}

/* line 1022, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select .list .option.selected {
  color: #8e44ad;
  background: transparent;
}

/* line 1026, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select .list .option:hover {
  color: #8e44ad;
  background: transparent;
}

/* line 1033, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1037, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.default-select .nice-select::after {
  right: 20px;
}

/* line 1042, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select {
  height: 40px;
  width: 100%;
}

/* line 1045, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}

/* line 1053, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}

/* line 1060, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}

/* line 1067, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select .list .option.selected {
  color: #8e44ad;
  background: transparent;
}

/* line 1071, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select .list .option:hover {
  color: #8e44ad;
  background: transparent;
}

/* line 1078, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}

/* line 1082, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.form-select .nice-select::after {
  right: 20px;
}

/* line 1086, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.mt-10 {
  margin-top: 10px;
}

/* line 1089, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}

/* line 1093, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.mb-30 {
  margin-bottom: 30px;
}

/* line 1096, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.mt-30 {
  margin-top: 30px;
}

/* line 1099, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_elements.scss */
.switch-wrap {
  margin-bottom: 10px;
}

/**************** review_part css start ****************/
/* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.review_part {
  position: relative;
}

/* line 6, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.review_part .section_tittle h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 0 25px 62px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

@media (max-width: 576px) {
  /* line 6, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2 {
    margin: 0 25px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 6, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2 {
    margin: 0 25px 25px;
  }
}

/* line 25, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.review_part .section_tittle h2:after {
  position: absolute;
  content: "";
  width: 253px;
  height: 1px;
  background-color: #000;
  top: 13px;
  left: 0;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 25, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:after {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 25, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:after {
    width: 150px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1680px) {
  /* line 25, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:after {
    width: 220px;
  }
}

@media (max-width: 991px) {
  /* line 25, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:after {
    display: none;
  }
}

/* line 52, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.review_part .section_tittle h2:before {
  position: absolute;
  content: "";
  width: 253px;
  height: 1px;
  background-color: #000;
  top: 13px;
  right: 0;
  z-index: -1;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 52, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:before {
    width: 130px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 52, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:before {
    width: 150px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1680px) {
  /* line 52, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:before {
    width: 220px;
  }
}

@media (max-width: 991px) {
  /* line 52, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part .section_tittle h2:before {
    display: none;
  }
}

/* line 82, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.review_part .slick-list {
  padding: 0px !important;
}

@media (max-width: 991px) {
  /* line 2, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .review_part {
    overflow: hidden;
  }
}

/* line 95, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.client_review_text p {
  font-size: 14px;
  margin: 26px 0px 30px;
  line-height: 1.9;
  color: #797979;
}

@media (max-width: 991px) {
  /* line 95, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .client_review_text p {
    margin: 10px 0px 10px;
  }
}

/* line 108, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.client_review_text h3 {
  font-size: 24px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 15px;
}

@media (max-width: 991px) {
  /* line 108, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .client_review_text h3 {
    margin-bottom: 5px;
  }
}

/* line 121, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.client_review_text h5 {
  font-size: 14px;
  font-weight: 400;
  color: #797979;
}

/* line 130, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider-nav-thumbnails .slick-slide {
  margin: 300px 0px 20px;
}

@media (max-width: 991px) {
  /* line 130, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider-nav-thumbnails .slick-slide {
    margin: 30px 0px 20px;
  }
}

/* line 139, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider-nav-thumbnails .slick-slide img {
  width: 120px;
  margin: 0 auto;
}

@media (max-width: 991px) {
  /* line 139, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider-nav-thumbnails .slick-slide img {
    width: 60px;
  }
}

/* line 152, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider-nav-thumbnails {
  z-index: 99;
}

/* line 155, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider-nav-thumbnails .slick-center {
  opacity: 1;
}

/* line 158, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider-nav-thumbnails .slick-center img {
  width: 200px;
  margin: 0 auto;
  margin-top: -290px;
  float: none;
}

@media (max-width: 991px) {
  /* line 158, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider-nav-thumbnails .slick-center img {
    margin-top: -25px;
    width: 100px;
  }
}

/* line 176, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider .slick-list {
  margin-top: -297px;
  background-color: #f7f7f7;
}

@media (max-width: 991px) {
  /* line 176, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider .slick-list {
    margin-top: 0;
  }
}

/* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
.slider .client_review_text {
  padding: 100px 50px;
}

@media (max-width: 991px) {
  /* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider .client_review_text {
    padding: 30px 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider .client_review_text {
    padding: 100px 50px 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_review.scss */
  .slider .client_review_text {
    padding: 100px 50px 25px;
  }
}

/* line 1, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.blog_bg {
  background-image: url("../img/blog_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* line 5, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 0;
}

/* line 10, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* line 21, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner {
  height: 550px;
  width: 100%;
  display: table;
}

/* line 26, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item {
  display: table-cell;
  vertical-align: middle;
}

/* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
  color: #fff;
  font-size: 72px;
  font-weight: 700;
  margin-top: 107px;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  /* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  /* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 30, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
  .breadcrumb .breadcrumb_iner .breadcrumb_iner_item h2 {
    font-size: 40px;
    margin-bottom: 10px;
  }
}

/* line 55, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item p {
  font-size: 15px;
  color: #fff;
}

/* line 60, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_breadcrumb.scss */
.breadcrumb .breadcrumb_iner .breadcrumb_iner_item span {
  margin: 0px 5px;
  font-size: 12px;
}

/* Start Blog Area css
============================================================================================ */
/* line 6, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.latest-blog-area .area-heading {
  margin-bottom: 70px;
}

/* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog {
  overflow: hidden;
  margin-bottom: 30px;
}

/* line 14, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog:hover {
  box-shadow: 0px 10px 20px 0px rgba(42, 34, 123, 0.1);
}

/* line 17, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .thumb {
  overflow: hidden;
  position: relative;
}

/* line 20, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .thumb:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 33, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog h4 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 34px;
  margin-bottom: 25px;
}

/* line 39, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 47, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .date {
  color: #666666;
  text-align: left;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}

/* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .tag {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  font-weight: 300;
  margin-right: 22px;
  position: relative;
}

/* line 63, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .tag:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 10px;
  background: #acacac;
  right: -12px;
  top: 7px;
}

@media (max-width: 1199px) {
  /* line 54, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog .tag {
    margin-right: 8px;
  }
  /* line 75, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog .tag:after {
    display: none;
  }
}

/* line 80, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .likes {
  margin-right: 16px;
}

@media (max-width: 800px) {
  /* line 11, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog {
    margin-bottom: 30px;
  }
}

/* line 86, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .single-blog-content {
  padding: 30px;
}

/* line 89, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .single-blog-content .meta-bottom p {
  font-size: 13px;
  font-weight: 300;
}

/* line 93, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog .single-blog-content .meta-bottom i {
  color: #e8ebef;
  font-size: 13px;
  margin-right: 7px;
}

@media (max-width: 1199px) {
  /* line 86, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog .single-blog-content {
    padding: 15px;
  }
}

/* line 105, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-blog:hover .thumb:after {
  opacity: .7;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 1199px) {
  /* line 112, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog h4 {
    transition: all 300ms linear 0s;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 14px;
    margin-bottom: 12px;
  }
  /* line 117, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-blog h4 a {
    font-size: 18px;
  }
}

/* line 125, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog {
  position: relative;
}

/* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog .single-blog-content {
  position: absolute;
  left: 35px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (min-width: 992px) {
  /* line 127, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .full_image.single-blog .single-blog-content {
    bottom: 100px;
  }
}

/* line 144, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog h4 {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-bottom: none;
  padding-bottom: 5px;
}

/* line 149, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog a {
  font-size: 20px;
  font-weight: 600;
}

/* line 157, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog .date {
  color: #fff;
}

/* line 161, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.full_image.single-blog:hover .single-blog-content {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* End Blog Area css
============================================================================================ */
/* Latest Blog Area css
============================================================================================ */
/* line 187, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.l_blog_item .l_blog_text .date {
  margin-top: 24px;
  margin-bottom: 15px;
}

/* line 190, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.l_blog_item .l_blog_text .date a {
  font-size: 12px;
}

/* line 195, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.l_blog_item .l_blog_text h4 {
  font-size: 18px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 0px;
  padding-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 206, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.l_blog_item .l_blog_text p {
  margin-bottom: 0px;
  padding-top: 20px;
}

/* End Latest Blog Area css
============================================================================================ */
/* Causes Area css
============================================================================================ */
/* line 221, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_slider .owl-dots {
  text-align: center;
  margin-top: 80px;
}

/* line 224, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_slider .owl-dots .owl-dot {
  height: 14px;
  width: 14px;
  background: #eeeeee;
  display: inline-block;
  margin-right: 7px;
}

/* line 230, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_slider .owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

/* line 239, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item {
  background: #fff;
}

/* line 241, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_img {
  position: relative;
}

/* line 243, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_img .c_parcent {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  height: 3px;
  background: rgba(255, 255, 255, 0.5);
}

/* line 250, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_img .c_parcent span {
  width: 70%;
  height: 3px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}

/* line 257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_img .c_parcent span:before {
  content: "75%";
  position: absolute;
  right: -10px;
  bottom: 0px;
  color: #fff;
  padding: 0px 5px;
}

/* line 269, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_text {
  padding: 30px 35px 40px 30px;
}

/* line 271, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_text h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
}

/* line 282, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_text p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 0px;
}

/* line 291, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_bottom a {
  width: 50%;
  border: 1px solid;
  text-align: center;
  float: left;
  line-height: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

/* line 302, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.causes_item .causes_bottom a + a {
  border-color: #eeeeee;
  background: #fff;
  font-size: 14px;
}

/* End Causes Area css
============================================================================================ */
/*================= latest_blog_area css =============*/
/* line 317, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.latest_blog_area {
  background: #f9f9ff;
}

/* line 320, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post {
  margin-bottom: 30px;
}

/* line 322, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post .thumb {
  overflow: hidden;
}

/* line 324, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post .thumb img {
  transition: all 0.7s linear;
}

/* line 328, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post .details {
  padding-top: 30px;
}

/* line 330, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post .details .sec_h4 {
  line-height: 24px;
  padding: 10px 0px 13px;
  transition: all 0.3s linear;
}

/* line 339, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post .date {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* line 345, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-recent-blog-post:hover img {
  transform: scale(1.23) rotate(10deg);
}

/* line 351, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.tags .tag_btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  padding: 1px 18px;
  text-align: center;
}

/* line 363, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.tags .tag_btn + .tag_btn {
  margin-left: 2px;
}

/*========= blog_categorie_area css ===========*/
/* line 370, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_categorie_area {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 900px) {
  /* line 370, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_categorie_area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media (min-width: 1100px) {
  /* line 370, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_categorie_area {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

/* line 385, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer;
}

/* line 389, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post img {
  max-width: 100%;
}

/* line 392, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post .categories_details {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(34, 34, 34, 0.75);
  color: #fff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* line 404, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post .categories_details h5 {
  margin-bottom: 0px;
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
}

/* line 421, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post .categories_details p {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 0px;
}

/* line 427, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post .categories_details .border_line {
  margin: 10px 0px;
  background: #fff;
  width: 100%;
  height: 1px;
}

/* line 435, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.categories_post:hover .categories_details {
  background: rgba(222, 99, 32, 0.85);
}

/*============ blog_left_sidebar css ==============*/
/* line 450, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_item {
  margin-bottom: 50px;
}

/* line 453, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_details {
  padding: 30px 0 20px 10px;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.3);
}

@media (min-width: 768px) {
  /* line 453, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_details {
    padding: 60px 30px 35px 35px;
  }
}

/* line 461, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_details p {
  margin-bottom: 30px;
}

/* line 464, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_details a {
  color: #2a2a2a;
}

/* line 466, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_details a:hover {
  color: #8e44ad;
}

/* line 470, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_details h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  /* line 470, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_details h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

/* line 483, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link li {
  float: left;
  font-size: 14px;
}

/* line 487, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link li a {
  color: #999999;
}

/* line 491, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link li i, .blog-info-link li span {
  font-size: 13px;
  margin-right: 5px;
}

/* line 496, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link li::after {
  content: "|";
  padding-left: 10px;
  padding-right: 10px;
}

/* line 502, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link li:last-child::after {
  display: none;
}

/* line 507, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-info-link::after {
  content: "";
  display: block;
  clear: both;
  display: table;
}

/* line 514, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_item_img {
  position: relative;
}

/* line 517, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_item_img .blog_item_date {
  position: absolute;
  bottom: -10px;
  left: 10px;
  display: block;
  color: #fff;
  background: #8e44ad;
  padding: 8px 15px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  /* line 517, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_item_img .blog_item_date {
    bottom: -20px;
    left: 40px;
    padding: 13px 30px;
  }
}

/* line 533, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_item_img .blog_item_date h3 {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  line-height: 1.2;
}

@media (min-width: 768px) {
  /* line 533, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_item_img .blog_item_date h3 {
    font-size: 30px;
  }
}

/* line 545, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_item_img .blog_item_date p {
  font-size: 18px;
  margin-bottom: 0;
  color: #fff;
}

@media (min-width: 768px) {
  /* line 545, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .blog_item_img .blog_item_date p {
    font-size: 18px;
  }
}

/* line 563, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .widget_title {
  font-size: 20px;
  margin-bottom: 40px;
}

/* line 568, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .widget_title::after {
  content: "";
  display: block;
  padding-top: 15px;
  border-bottom: 1px solid #f0e9ff;
}

/* line 576, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .single_sidebar_widget {
  background: #fbf9ff;
  padding: 30px;
  margin-bottom: 30px;
}

/* line 585, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .search_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
  border-right: 0;
}

/* line 594, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .search_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 603, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .search_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 610, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .search_widget .input-group button i, .blog_right_sidebar .search_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 621, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control {
  height: 50px;
  border-color: #f0e9ff;
  font-size: 13px;
  color: #999999;
  padding-left: 20px;
  border-radius: 0;
}

/* line 630, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .form-control:focus {
  border-color: #f0e9ff;
  outline: 0;
  box-shadow: none;
}

/* line 639, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button {
  background: #fff;
  border-left: 0;
  border: 1px solid #f0e9ff;
  padding: 4px 15px;
  border-left: 0;
}

/* line 646, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .newsletter_widget .input-group button i, .blog_right_sidebar .newsletter_widget .input-group button span {
  font-size: 14px;
  color: #999999;
}

/* line 658, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li {
  border-bottom: 1px solid #f0e9ff;
  transition: all 0.3s ease 0s;
  padding-bottom: 12px;
}

/* line 663, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li:last-child {
  border-bottom: 0;
}

/* line 667, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a {
  font-size: 14px;
  line-height: 20px;
  color: #888888;
}

/* line 671, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li a p {
  margin-bottom: 0px;
}

/* line 675, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .post_category_widget .cat-list li + li {
  padding-top: 15px;
}

/* line 690, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body {
  justify-content: center;
  align-self: center;
  padding-left: 20px;
}

/* line 694, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 6px;
  transition: all 0.3s linear;
}

/* line 703, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
  color: #8e44ad;
}

/* line 708, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item .media-body p {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
}

/* line 714, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .popular_post_widget .post_item + .post_item {
  margin-top: 20px;
}

/* line 722, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li {
  display: inline-block;
}

/* line 724, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a {
  display: inline-block;
  border: 1px solid #eeeeee;
  background: #fff;
  padding: 4px 20px;
  margin-bottom: 8px;
  margin-right: 3px;
  transition: all 0.3s ease 0s;
  color: #888888;
  font-size: 13px;
}

/* line 734, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .tag_cloud_widget ul li a:hover {
  background: #8e44ad;
  color: #fff;
}

/* line 745, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row {
  display: flex;
  margin-right: -6px;
  margin-left: -6px;
}

/* line 751, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .instagram_feeds .instagram_row li {
  width: 33.33%;
  float: left;
  padding-right: 6px;
  padding-left: 6px;
  margin-bottom: 15px;
}

/* line 851, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog_right_sidebar .br {
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 30px 0px;
}

/* line 872, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-pagination {
  margin-top: 80px;
}

/* line 876, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-pagination .page-link {
  font-size: 14px;
  position: relative;
  display: block;
  padding: 0;
  text-align: center;
  margin-left: -1px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 0 !important;
  color: #8a8a8a;
  border: 1px solid #f0e9ff;
  margin-right: 10px;
}

/* line 893, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-pagination .page-link i, .blog-pagination .page-link span {
  font-size: 13px;
}

/* line 904, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-pagination .page-item.active .page-link {
  background-color: #fbf9ff;
  border-color: #f0e9ff;
  color: #888888;
}

/* line 911, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.blog-pagination .page-item:last-child .page-link {
  margin-right: 0;
}

/*============ Start Blog Single Styles  =============*/
/* line 936, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog_details {
  box-shadow: none;
  padding: 0;
}

/* line 941, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .social-links {
  padding-top: 10px;
}

/* line 943, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}

/* line 946, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .social-links li a {
  color: #cccccc;
  padding: 7px;
  font-size: 14px;
  transition: all 0.2s linear;
}

/* line 957, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog_details {
  padding-top: 26px;
}

/* line 959, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog_details p {
  margin-bottom: 20px;
  font-size: 15px;
}

/* line 967, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .quote-wrapper {
  background: rgba(130, 139, 178, 0.1);
  padding: 15px;
  line-height: 1.733;
  color: #888888;
  font-style: italic;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  /* line 967, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .quote-wrapper {
    padding: 30px;
  }
}

/* line 980, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .quotes {
  background: #fff;
  padding: 15px 15px 15px 20px;
  border-left: 2px solid;
}

@media (min-width: 768px) {
  /* line 980, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .quotes {
    padding: 25px 25px 25px 30px;
  }
}

/* line 989, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .arrow {
  position: absolute;
}

/* line 991, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}

/* line 997, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}

/* line 1001, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top {
  padding-top: 15px;
  border-top: 1px solid #f0e9ff;
}

/* line 1005, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top p {
  margin-bottom: 0;
}

/* line 1009, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .like-info {
  font-size: 14px;
}

/* line 1012, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .like-info i, .single-post-area .navigation-top .like-info span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1018, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .comment-count {
  font-size: 14px;
}

/* line 1021, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .comment-count i, .single-post-area .navigation-top .comment-count span {
  font-size: 16px;
  margin-right: 5px;
}

/* line 1029, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li {
  display: inline-block;
  margin-right: 15px;
}

/* line 1033, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li:last-child {
  margin: 0;
}

/* line 1037, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-top .social-icons li i, .single-post-area .navigation-top .social-icons li span {
  font-size: 14px;
  color: #999999;
}

/* line 1053, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author {
  padding: 40px 30px;
  background: #fbf9ff;
  margin-top: 50px;
}

@media (max-width: 600px) {
  /* line 1053, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .blog-author {
    padding: 20px 8px;
  }
}

/* line 1062, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-right: 30px;
}

@media (max-width: 600px) {
  /* line 1062, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .blog-author img {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }
}

/* line 1075, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author a {
  display: inline-block;
}

/* line 1078, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author a:hover {
  color: #8e44ad;
}

/* line 1083, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author p {
  margin-bottom: 0;
  font-size: 15px;
}

/* line 1088, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .blog-author h4 {
  font-size: 16px;
}

/* line 1095, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area {
  border-bottom: 1px solid #eee;
  padding-bottom: 30px;
  margin-top: 55px;
}

/* line 1099, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area p {
  margin-bottom: 0px;
}

/* line 1102, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area h4 {
  font-size: 18px;
  line-height: 25px;
}

/* line 1107, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left {
  text-align: left;
}

/* line 1109, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}

/* line 1112, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1116, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1122, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}

/* line 1126, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: .5;
}

@media (max-width: 767px) {
  /* line 1107, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}

/* line 1135, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right {
  text-align: right;
}

/* line 1137, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}

/* line 1140, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1144, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 1150, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}

/* line 1154, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: .5;
}

@media (max-width: 991px) {
  /* line 1162, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}

/* line 1169, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area {
  background: transparent;
  border-top: 1px solid #eee;
  padding: 45px 0;
  margin-top: 50px;
}

@media (max-width: 414px) {
  /* line 1169, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comments-area {
    padding: 50px 8px;
  }
}

/* line 1178, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area h4 {
  margin-bottom: 35px;
  font-size: 18px;
}

/* line 1184, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

/* line 1191, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .comment-list {
  padding-bottom: 48px;
}

/* line 1193, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}

/* line 1196, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}

@media (max-width: 413px) {
  /* line 1201, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  /* line 1204, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  /* line 1207, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}

/* line 1213, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .thumb {
  margin-right: 20px;
}

/* line 1216, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .thumb img {
  width: 70px;
  border-radius: 50%;
}

/* line 1221, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .date {
  font-size: 14px;
  color: #999999;
  margin-bottom: 0;
  margin-left: 20px;
}

/* line 1227, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .comment {
  margin-bottom: 10px;
  color: #777777;
  font-size: 15px;
}

/* line 1232, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comments-area .btn-reply {
  background-color: transparent;
  color: #888888;
  padding: 5px 18px;
  font-size: 14px;
  display: block;
  font-weight: 400;
}

/* line 1249, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form {
  border-top: 1px solid #eee;
  padding-top: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
}

/* line 1257, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .form-group {
  margin-bottom: 30px;
}

/* line 1261, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form h4 {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 22px;
}

/* line 1268, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .name {
  padding-left: 0px;
}

@media (max-width: 767px) {
  /* line 1268, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comment-form .name {
    padding-right: 0px;
    margin-bottom: 1rem;
  }
}

/* line 1275, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .email {
  padding-right: 0px;
}

@media (max-width: 991px) {
  /* line 1275, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
  .comment-form .email {
    padding-left: 0px;
  }
}

/* line 1281, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 1289, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 1294, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 1299, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form .form-control::placeholder {
  color: #777777;
}

/* line 1303, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form textarea {
  padding-top: 18px;
  border-radius: 12px;
  height: 100% !important;
}

/* line 1308, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  color: #777;
}

/* line 1313, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  color: #777;
}

/* line 1318, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  color: #777;
}

/* line 1323, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_blog.scss */
.comment-form :-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  color: #777;
}

/*============ End Blog Single Styles  =============*/
/* Main Button Area css
============================================================================================ */
/* line 3, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.submit_btn {
  width: auto;
  display: inline-block;
  background: #fff;
  padding: 0px 50px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 50px;
  border-radius: 5px;
  outline: none !important;
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #e8ebef;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* line 19, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.submit_btn:hover {
  background: transparent;
}

/* line 24, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_1 {
  padding: 19px 40px;
  font-size: 16px;
  font-weight: 700;
  background-color: #f4f4f4;
  color: #000;
  display: inline-block;
  text-transform: uppercase;
  -webkit-transition: 1s;
  transition: 1s;
  border-radius: 5px;
}

/* line 34, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_1:hover {
  background-color: #8e44ad;
  color: #fff;
  border-radius: 50px;
}

/* line 38, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_1:hover a {
  color: #fff;
}

/* line 42, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_1 a {
  color: #fff;
}

/* line 44, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_1 a:hover {
  color: #fff;
}

@media (max-width: 991px) {
  /* line 24, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
  .btn_1 {
    padding: 12px 32px;
  }
}

/* line 55, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_3 {
  padding: 8px 30px;
}

/* line 58, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_2 {
  position: relative;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  z-index: 9;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* line 65, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_2:after {
  height: 30px;
  width: 30px;
  background-color: #8e44ad;
  position: absolute;
  content: "";
  top: -5px;
  left: -11px;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

/* line 77, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_2 a {
  color: #fff;
  font-weight: 500;
}

/* line 81, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.btn_2 span {
  margin-left: 6px;
}

/*=================== custom button rule start ====================*/
/* line 88, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button {
  display: inline-block;
  border: 1px solid transparent;
  font-size: 15px;
  font-weight: 500;
  padding: 12px 54px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #e8ebef;
  text-transform: uppercase;
  background-color: #8e44ad;
  cursor: pointer;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 767px) {
  /* line 88, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
  .button {
    font-size: 13px;
    padding: 9px 24px;
  }
}

/* line 107, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button:hover {
  color: #fff;
}

/* line 112, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button-link {
  letter-spacing: 0;
  color: #3b1d82;
  border: 0;
  padding: 0;
}

/* line 118, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button-link:hover {
  background: transparent;
  color: #3b1d82;
}

/* line 124, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button-header {
  color: #fff;
  border-color: #e8ebef;
}

/* line 128, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button-header:hover {
  background: #b8024c;
  color: #fff;
}

/* line 134, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_button.scss */
.button-contactForm {
  color: #fff;
  border-color: #e8ebef;
  padding: 12px 25px;
}

/* End Main Button Area css
============================================================================================ */
/*=================== contact banner start ====================*/
/* line 3, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info {
  margin-bottom: 25px;
}

/* line 6, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info__icon {
  margin-right: 20px;
}

/* line 9, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info__icon i, .contact-info__icon span {
  color: #8f9195;
  font-size: 27px;
}

/* line 17, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info .media-body h3 {
  font-size: 16px;
  margin-bottom: 0;
}

/* line 22, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info .media-body h3 a:hover {
  color: #8e44ad;
}

/* line 28, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-info .media-body p {
  color: #8a8a8a;
}

/*=================== contact banner end ====================*/
/*=================== contact form start ====================*/
/* line 37, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.contact-title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* line 45, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact label {
  font-size: 14px;
}

/* line 49, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact .form-group {
  margin-bottom: 30px;
}

/* line 53, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact .form-control {
  border: 1px solid #f0e9ff;
  border-radius: 5px;
  height: 48px;
  padding-left: 18px;
  font-size: 13px;
  background: transparent;
}

/* line 61, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/* line 66, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact .form-control::placeholder {
  font-weight: 300;
  color: #999999;
}

/* line 72, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.form-contact textarea {
  border-radius: 12px;
  height: 100% !important;
}

/*=================== contact form end ====================*/
/* Contact Success and error Area css
============================================================================================ */
/* line 89, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}

/* line 98, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* line 104, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

/* line 113, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

/* line 118, ../../01 cl html template/New folder/May 2019/178 Real Estate/Real Estate Html/sass/_contact.scss */
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}

/*# sourceMappingURL=style.css.map */
