body,
html {
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif, fantasy;
}

.ricoPaRica{
    text-decoration-color: red;
    color: black;
    text-align: center;
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: #F05F40;
}

hr.light {
  border-color: #fff;
}

a {
  color: #F05F40;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.a:hover {
  color: #f05f40;
}

h1,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

h3 {
     background-position: center center;
    width: 350px;
    height: 40px;
    background-color:orangered;
    align-content: center;
    position: relative;
    -webkit-animation-name: example; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
    -webkit-animation-iteration-count: 2; /* Safari 4.0 - 8.0 */
    -webkit-animation-direction: alternate; /* Safari 4.0 - 8.0 */
    animation-name: example;
    animation-duration: 7s;
    animation-iteration-count: 10;
    animation-direction: alternate-reverse;
    font-family: 'Rammetto One', cursive;
}



/* Standard syntax */
@-webkit-keyframes example {
    0%   {background-image:red; left:0px; top:0px;}
    25%  {background-color:yellow; left:1200px; top:0px;}
    50%  {background-color:blue; left:1200px; top:200px;}
    75%  {background-color:green; left:0px; top:200px;}
    100% {background-color:red; left:0px; top:0px;}
}

h2{
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    color: crimson;
}

.Holaaaa{
    background-image: url("http://imagenes.4ever.eu/data/download/abstractos/cuadros-abstractos-172471.jpg?no-logo");
}

.bg-primary {
  width: auto;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'K2D', sans-serif;
  background-position: center;
}

.bg-primary2 {
  width: auto;
  height: auto;
  /*height: 600px;*/
  overflow: auto;
  text-overflow: ellipsis;
  /*white-space: nowrap;*/
  /*max-height: 100%;*/
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'K2D', sans-serif;
  /*background-position: 10% 40%;*/
}
.bg-primary3 {
  width: 100%;
  height: 500px;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: 'K2D', sans-serif;
}
.usuarios{
  clear:both;
  float:left;
  width:100%;
  list-style:none;
  margin:0;
  padding:0;
  position:
}
.usuarios li{
  display: inline-block;
}
.bg-primary31 {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);
  background-color: #8e44ad !important;
  background-repeat: no-repeat;
  background-size: 460px;
  background-position: left;
}
.bg-primary4 {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  bottom;
  font-family: 'K2D', sans-serif;
}
.bg-primary5 {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  0% 80%;
  font-family: 'K2D', sans-serif;
}
.bg-primary6 {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center;
  font-family: 'K2D', sans-serif;
}
.bg-primary7 {
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  bottom;
  font-family: 'K2D', sans-serif;
}
.bg-primary8 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position:  center;
  font-family: 'K2D', sans-serif;
}

.ricoPaRica{
  font-family: 'K2D', sans-serif;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px black
}

.bg-dark {
  background-color: #8e44ad !important;
}

.text-faded-mb-5{
    color: fuchsia;
}


.text-faded {
  color: rgba(255, 255, 255, 0.7);

}
.coso{
  color: rgb(253, 253, 253);
  margin-left: 32%;
  text-align:justify;
}
.cosofaded{
  color: #8e44ad;
}

section {
  padding: 8rem 0;
}

.section-heading {
  margin-top: 0;
}

::-moz-selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

::selection {
  color: #fff;
  background: #212529;
  text-shadow: none;
}

img::selection {
  color: #fff;
  background: transparent;
}

img::-moz-selection {
  color: #fff;
  background: transparent;
}

#mainNav {
  border-bottom: 1px solid rgba(33, 37, 41, 0.1);
  background-color: #8e44ad;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

#mainNav .navbar-brand {
  font-weight: 800;
  text-transform: uppercase;
  color: #F05F40;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

#mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
  color: #f05f40;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
  color: #212529;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
  color: #F05F40;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active {
  color: #F05F40 !important;
  background-color: #8e44ad;
}

#mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active:hover {
  background-color: #8e44ad;
}

@media (min-width: 992px) {
  #mainNav {
    border-color: transparent;
    background-color: #8e44ad;
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: #fff;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link {
    padding: 0.5rem 1rem;
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: #fff;
  }
  #mainNav.navbar-shrink {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background-color: black;
  }
  #mainNav.navbar-shrink .navbar-brand {
    color: #fff;
  }
  #mainNav.navbar-shrink .navbar-brand:focus, #mainNav.navbar-shrink .navbar-brand:hover {
    color: #f05f40;
  }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus {
    color: #fff;
  }
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:hover,
  #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus:hover {
    color: #F05F40;
  }
}

header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 56px);

  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

header.masthead hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

header.masthead h1 {
  font-size: 2rem;
}

header.masthead p {
  font-weight: 300;
}

@media (min-width: 768px) {
  header.masthead p {
    font-size: 1.15rem;
  }
}

@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 650px;
    padding-top: 0;
    padding-bottom: 0;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 4rem;
  }
}

.service-box {
  max-width: 400px;
}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}

.portfolio-box .portfolio-box-caption {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
  color: #fff;
  background: rgba(240, 95, 64, 0.9);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  padding: 0 15px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px;
}

.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}

.portfolio-box:focus {
  outline: none;
}

@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px;
  }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px;
  }
}


.text-primary {
  color: #F05F40 !important;
}

h19{
    font-size: 40px;
    text-align: center;
    font-family: 'Pacifico', cursive;
    color: white;
}

.btn {
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 300px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}

.btn-xl {
  padding: 1rem 2rem;
}

.btn-primary {
  background-color: #F05F40;
  border-color: #F05F40;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  color: #fff;
  background-color: #ee4b28 !important;
}

.btn-primary:active, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 95, 64, 0.5) !important;
}

.purple{
  background-color: #8e44ad;
}
.icono{
  max-width: 30px
}
